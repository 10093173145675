<template>
    <div class="recon-attach">
        <div class="recon-attach__section">
            <div class="recon-attach__section-title">
                Выберите месяц для загрузки сверок
            </div>
            <div class="recon-attach__section-content">
                <date-picker-dropdown 
                    :isMultiple="false"
                    :checkedDates="selectedDates"
                    @onApprove="onApprove" 
                />
            </div>
        </div>
        <div class="recon-attach__section">
            <div class="recon-attach__section-title">
                Прикрепите файл
            </div>
            <div class="recon-attach__section-content">
                <attach-file
                    :files="files"
                    :isMulti="true"
                    @change="onChangeFiles"
                    @clear="onClearFile"
                />
            </div>
            <div class="recon-attach__section-actions">
                <base-button
                    :disabled="isDisabledAttach" 
                    view="secondary"
                    class="recon-attach__section-btn"
                    @click="onAttach"
                >
                    Прикрепить
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
/** Компонент для прикрепления фалов сверок */
import DatePickerDropdown from "@/components/DatePickerDropdown";
import AttachFile from "@/components/AttachFile";
import BaseButton from "@/components/Base/BaseButton";
import ServiceReconciliationDownload from "@/services/ServicesReconciliation/ServiceReconciliationDownload";
export default {
    name: 'FormReconciliationAttach',
    components: {
        DatePickerDropdown, 
        AttachFile,
        BaseButton
    },
    /**
     * Данные компонента
     * @property {Array} files - файлы
     * @property {Array} selectedDates - выбранные даты
     * @property {Object} ServiceReconciliationDownload - инстанс сервиса загрузок сверок
     */
    data: () => ({
        files: [],
        selectedDates: [],
        ServiceReconciliationDownload: {}
    }),
    computed: {
        /**
         * Условие активности кнопки прикрепить
         * @returns {Boolean}
         */
        isDisabledAttach() {
            return this.files.length === 0 || this.selectedDates.length === 0;
        }
    },
    mounted() {
        /** Создаем инстанс для сервиса */
        this.ServiceReconciliationDownload = new ServiceReconciliationDownload(20);
    },
    methods: {
        /**
         * Изменить массив файлов
         * @param {Array} files - файлы
         */
        onChangeFiles(files = []) {
            const filesFiltered = this.ServiceReconciliationDownload.validationFiles(Array.from(files));
            this.files.push(...filesFiltered);
        },
        /**
         * Удалить файл из массива файлов
         * @param {String} name - название файла
         */
        onClearFile(name = '') {
            const index = this.files.findIndex(file => {
                const {name: fileName = ''} = file;
                return String(fileName) === String(name);
            });
            if (index !== -1)
                this.files.splice(index, 1);
        },
        /**
         * Выбор даты
         * @param {Array} dates - даты
         */
        onApprove(dates = []) {
            this.selectedDates = dates;
        },
        /**
         * Прикрепить
         */
        onAttach() {
            this.$emit('onAttach', this.files, this.selectedDates);
            this.files = [];
        }
    }
}
</script>

<style lang="scss" scoped>
    .recon-attach {
        &__section {
            &:not(:first-child) {
                margin-top: 20px;
            }
            &-content {
                margin-top: 8px;
            }
            &-title {
               font-weight: 500; 
            }
            &-actions {
                margin-top: 8px;
                display: flex;
                flex-direction: row-reverse;
            }
            &-btn {
                border-radius: 1000px;
            }
        }
    }
</style>