<template>
    <div class="reconciliation-container">
        <reconciliation-container-header
            :fileName="fileName"
            :templateName="templateName"
            :found="found"
            :notFound="notFound"
            :showStat="reconciliation.canUpload"
            @onClickName="onClickName"
        />
        <div v-if="isShowContent">
<!--            <tab-list
                :tabs="tabs"
                :tabIdActive="tabIdActive"
                class="reconciliation-container__tabs"
                @onCheck="onCheckTab"
            />
            <div class="reconciliation-container__info">
                <div
                    v-for="(infoItem, infoIndex) in info"
                    :key="infoIndex"
                    class="reconciliation-container__info-item"
                >
                    {{infoItem}}
                </div>
            </div>-->
            <reconciliation-table
                :rows="reconciliation.report"
                class="reconciliation-container__table"
            />
        </div>
    </div>
</template>

<script>
/** Контейнер для сверки */
import MixinReconciliationItem from "@/mixins/MixinsReconciliation/MixinReconciliationItem";
import ReconciliationContainerHeader from "./ReconciliationContainerHeader";
// import TabList from '@/components/Tabs/TabList';
import ReconciliationTable from './ReconciliationTable';
export default {
    name: 'ReconciliationContainer',
    components: {
        ReconciliationContainerHeader,
        // TabList,
        ReconciliationTable
    },
    mixins: [MixinReconciliationItem],
    /**
     * Данные компонента
     * @property {Array} tabs - табы по клиентам
     * @property {String} tabIdActive - идентификатор активного таба
     * @property {Array} info - информация
     * @property {String} tabIdActive - идентификатор активного таба
     * @property {Boolean} isShowContent - условие показа контента контейнера сверки
     */
    data: () => ({
        tabs: [
            {
                id: '0',
                name: 'Яндекс'
            },
            {
                id: '1',
                name: 'Самолет'
            },
            {
                id: '2',
                name: 'ЛСР Групп'
            }
        ],
        info: [
            '+40 новых сторон в файле',
            '12 сторон присутствуют в адресной программе, но отсутствуют в файле'
        ],
        tabIdActive: '0',
        isShowContent: true
    }),
    computed: {
        /**
         * Найдено
         * @returns {Number}
         */
        found() {
            const {found = ''} = this.reconciliation;
            return parseInt(found);
        },
        /**
         * Не найдено
         * @returns {Number}
         */
        notFound() {
            const {notFound = ''} = this.reconciliation;
            return parseInt(notFound);
        }
    },
    methods: {
        /**
         * Выбор таба по клиентам
         * @param {String} id - идентификатор таба
         */
        onCheckTab(id = '-1') {
            this.tabIdActive = String(id);
        },
        /**
         * Клик по имени файла
         */
        onClickName() {
            this.isShowContent = !this.isShowContent;
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-container {
        border: 1px solid;
        border-radius: 10px;
        border-color: #DCE1E8;
        padding: 28px 66px;
        margin-bottom: 5px;
        max-height: 500px;
        overflow-y: hidden;
        &__tabs {
            margin-top: 40px;
            margin-bottom: 30px;
        }
        &__info-item {
            font-size: 12px;
            color: #F84967;
        }
        &__table {
            margin-top: 64px;
            max-height: 300px;
            overflow-y: scroll;
        }
    }
</style>
