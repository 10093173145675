<template>
	<div class="steps">
		<AppStep
			v-for="(step, index) of steps"
			:key="index"
			:isActive="step.id === activeStepId"
			class="step"
			@check="onStep(step)"
		>
			<span class="step-title">
				<b>Шаг {{ index+1 }}</b>
				{{ step.title }}
			</span>
		</AppStep>
	</div>
</template>

<script>
import AppStep from "./Step"

export default {
	name: "AppSteps",
	components: {
		AppStep,
	},
	props: {
		steps: {
			type: Array,
			default: () => []
		},
		activeStepId: {
			type: [Number, String],
			default: 0
		}
	},
	methods: {
		onStep(stepData) {
			const stepIndex = this.steps.findIndex(step => step.id === stepData.id)
			const previousSteps = this.steps.slice(0, stepIndex)
			let canCheck = true

			previousSteps.forEach(item => {
				if (item.canNext !== true) {
					canCheck = false
				}
			})

			if (canCheck) {
				this.$emit('checkStep', stepData)
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.steps {
	.step {
		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&-title {
			b {
				margin-right: 10px;
			}
		}
	}
}
</style>