<template>
    <div>
        <reconciliation-list-item
            v-for="(reconciliation, reconciliationIndex) in reconciliations"
            :key="reconciliationIndex"
            :reconciliation="reconciliation"
            @onRemove="onRemove"
            @onRedirectToTemplate="onRedirectToTemplate"
            @onRedirectToTemplateEdition="onRedirectToTemplateEdition"
        />
    </div>
</template>

<script>
/** Компонент для списка результатов проверки сверок */
import ReconciliationListItem from './ReconciliationListItem';
export default {
    name: 'ReconciliationList',
    components: {ReconciliationListItem},
    /**
     * Входные данные компонента
     * @property {Array} reconciliations - сверки
     */
    props: {
        reconciliations: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        /**
         * Удалить элемент списка сверок
         * @param {String} id - идентификатор
         */
        onRemove(id = '-1') {
            this.$emit('onRemove', id);
        },
        onRedirectToTemplate(id = '-1'){
            this.$emit('onRedirectToTemplateCreation', id);
        },
        onRedirectToTemplateEdition(fileId = '-1', presetId = '-1'){
            this.$emit('onRedirectToTemplateEdition', fileId, presetId);
        }
    }
}
</script>
