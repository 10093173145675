<template>
    <div class="reconciliation-step-attach">
        <div class="reconciliation-step-attach__left">
            <reconciliation-attach v-if="!presetFileId" @onAttach="onAttach"/>
            <reconciliation-set-date v-if="presetFileId" @onAttachDate="onAttachDate"/>
        </div>
        <div class="reconciliation-step-attach__right">
            <reconciliation-attach-res
                :reconciliations="reconciliations"
                @onRemove="onRemove"
                @onRedirectToTemplateCreation="onRedirectToTemplateCreation"
                @onRedirectToTemplateEdition="onRedirectToTemplateEdition"
            />
        </div>
    </div>
</template>

<script>
/** Компонент для первого шага */
import ReconciliationAttach from "@/components/Reconciliation/ReconciliationDownload/ReconciliationAttach";
import ReconciliationSetDate from "@/components/Reconciliation/ReconciliationDownload/ReconciliationSetDate";
import ReconciliationAttachRes from "@/components/Reconciliation/ReconciliationDownload/ReconciliationAttachRes";
export default {
    name: 'ReconciliationStepAttach',
    components: {
        ReconciliationAttach,
        ReconciliationAttachRes,
        ReconciliationSetDate
    },
    /**
     * Входные данные компонента
     * @property {Array} reconciliations - сверки
     */
    props: {
        reconciliations: {
            type: Array,
            default: () => ([])
        },
        presetFileId: {
            type: Number,
            default: null
        }
    },
    methods: {
        /**
         * Прикрепить
         * @param {Array} files - файлы
         * @param {Array} dates - даты
         */
        onAttach(files = [], dates = []) {
            this.$emit('onAttach', files, dates);
        },
        onAttachDate(dates = []) {
            this.$emit('onAttachDate', dates);
        },
        /**
         * Удалить элемент списка сверок
         * @param {String} id - идентификатор
         */
        onRemove(id = '-1') {
            this.$emit('onRemove', id);
        },
        onRedirectToTemplateCreation(id = '-1'){
            this.$emit('onRedirectToTemplateCreation', id);
        },
        onRedirectToTemplateEdition(fileId = '-1', presetId = '-1'){
            this.$emit('onRedirectToTemplateEdition', fileId, presetId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-step-attach {
        display: flex;
        height: 100%;
        &__left {
            width: 35%;
            margin-left: 60px;
            flex-grow: 1;
            height: 100%;
            overflow: hidden;
        }
        &__right {
            width: 65%;
            margin-left: 60px;
        }
    }
</style>
