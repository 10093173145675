<template>
    <div class="recon-attach-res">
        <div class="recon-attach-res__title">
            Прикрепленные файлы
        </div>
        <reconciliation-list
            :reconciliations="reconciliations"
            class="recon-attach-res__list"
            @onRemove="onRemove"
            @onRedirectToTemplateCreation="onRedirectToTemplateCreation"
            @onRedirectToTemplateEdition="onRedirectToTemplateEdition"
        />
        <!-- <div class="recon-attach-res__actions">
            <base-button
                v-if="reconciliations.length !== 0"
                view="secondary"
                class="recon-attach-res__btn"
            >
                Отправить на проверку
            </base-button>
        </div> -->
    </div>
</template>

<script>
/** Компонент для отображения результатов прикрпеления сверок*/
import ReconciliationList from './ReconciliationList';
// import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'FormReconciliationAttachRes',
    components: {
        ReconciliationList,
        // BaseButton
    },
    /**
     * Входные данные компонента
     * @property {Array} reconciliations - сверки
     */
    props: {
        reconciliations: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        /**
         * Удалить элемент списка сверок
         * @param {String} id - идентификатор
         */
        onRemove(id = '-1') {
            this.$emit('onRemove', id);
        },
        onRedirectToTemplateCreation(id = '-1'){
            this.$emit('onRedirectToTemplateCreation', id);
        },
        onRedirectToTemplateEdition(fileId = '-1', presetId = '-1'){
            this.$emit('onRedirectToTemplateEdition', fileId, presetId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .recon-attach-res {
        background: #F9FAFB;
        padding-top: 82px;
        padding-bottom: 82px;
        padding-left: 48px;
        padding-right: 48px;
        &__title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 24px;
        }
        &__list {
            padding-left: 15px;
        }
        &__actions {
            margin-top: 8px;
            display: flex;
            flex-direction: row-reverse;
        }
        &__btn {
            border-radius: 1000px;
        }
    }
</style>
