<template>
    <div class="reconciliation-container-header">
        <div class="reconciliation-container-header__name">
            <base-icon
                icon="icon-document-3"
                style="font-size: 18px;"
            />
            <div
                class="reconciliation-container-header__name-file"
                @click="onClickName"
            >
                {{fileName}}
            </div>
            <div class="reconciliation-container-header__name-template">
                {{templateName}}
            </div>
        </div>
        <div v-if="showStat" class="reconciliation-container-header__info">
            <div class="reconciliation-container-header__info-icon reconciliation-container-header__info-icon_danger">
            </div>
            <div class="reconciliation-container-header__info_danger">
                {{notFound}} требуют внимания
            </div>
            <div class="reconciliation-container-header__info-icon reconciliation-container-header__info-icon_success">
                <base-icon
                    color="#FFFFFF"
                    icon="icon-tick"
                    style="font-size: 9px; padding-left: 2px;"
                />
            </div>
            <div class="reconciliation-container-header__info_success">
                {{found}} без изменений
            </div>
        </div>
        <!-- <div class="reconciliation-container-header__action">
            Свернуть
            <base-icon
                icon="icon-down"
                style="font-size: 12px; padding-left: 2px;"
            />
        </div> -->
    </div>
</template>

<script>
/** Компонент хэдера контейнера для сверки */
import BaseIcon from '@/components/Base/BaseIcon';
export default {
    name: 'ReconciliationContainerHeader',
    components: {BaseIcon},
    /**
     * Входные данные компонента
     * @property {String} fileName - название файла
     * @property {String} templateName - название шаблона
     * @property {Number} found - найдено
     * @property {Number} notFound - не найдено
     */
    props: {
        fileName: {
            type: String,
            default: ''
        },
        templateName: {
            type: String,
            default: ''
        },
        found: {
            type: Number,
            default: 0
        },
        notFound: {
            type: Number,
            default: 0
        },
        showStat:{
            type: Boolean,
            default: true
        }
    },
    /**
     * Данные компонента
     * @property {Number} countDanger - колличество цен, которые необходимо сравнить
     * @property {Number} countSuccess - колличество цен без изменений
     */
    data: () => ({
        countDanger: 456,
        countSuccess: 44
    }),
    methods: {
        /**
         * Клик по имени файла
         */
        onClickName() {
            this.$emit('onClickName')
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-container-header {
        display: flex;
        // justify-content: space-between;
        // height: 80px;
        &__name {
            display: flex;
            width: 350px;
            &-file {
                font-size: 16px;
                width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #000000;
                margin-left: 5px;
                cursor: pointer;
                user-select: none;
            }
            &-template {
                margin-top: 4px;
                font-size: 12px;
                margin-left: 30px;
                color: #ADB0BB
            }
        }
        &__info {
            margin-top: 4px;
            margin-left: 90px;
            display: flex;
            font-size: 12px;
            &_danger {
                color: #F84967
            }
            &_success {
                color: #000000
            }
            &-icon {
                margin: 0 10px 0 10px;
                border-radius: 1000px;
                width: 14px;
                height: 14px;
                &_danger {
                    background-color: #F84967
                }
                &_success {
                    background-color: #000000
                }
            }
        }
        &__action {
            display: flex;
            margin-top: 4px;
            cursor: pointer;
            color: #ADB0BB;
        }

    }
</style>
