<template>
    <div class="reconciliation-download">
        <div
			v-show="isLoading"
			class="loading"
		>
            <BasePreloader :size="80" />
        </div>
        <div class="steps reconciliation-download__left">
            <base-button
                view="simple"
                class="steps__cancel"
                @click="onCancel"
            >
                Отменить
            </base-button>
            <app-steps
                :steps="steps"
                :activeStepId="activeStepId"
                @checkStep="onCheckStep"
            />
            <base-button
                view="secondary"
                class="steps__continue"
                :disabled="isDisabledContinue"
                @click="onContinueStep"
            >
                {{titleContinueStep}}
            </base-button>
        </div>
        <reconciliation-step-attach
            v-if="activeStepId === 1"
            :reconciliations="reconciliations"
            :presetFileId="Number(presetFileId)"
            class="reconciliation-download__right"
            @onAttach="onAttach"
            @onAttachDate="onAttachDate"
            @onRemove="onRemove"
            @onRedirectToTemplateCreation="onRedirectCreation"
            @onRedirectToTemplateEdition="onRedirectEdition"
        />
        <reconciliation-step-validation
            v-if="activeStepId === 2"
            :reconciliations="reconciliationsCheckData"
            class="reconciliation-download__right"
        />
    </div>
</template>

<script>
/** Лайаут для страницы загрузки сверок */
import BasePreloader from "@/components/Base/BasePreloader";
import AppSteps from "@/components/Reconciliation/Steps/Steps";
import BaseButton from "@/components/Base/BaseButton";
import ReconciliationStepAttach from "@/components/Reconciliation/ReconciliationDownload/ReconciliationStepAttach";
import ReconciliationStepValidation from "@/components/Reconciliation/ReconciliationDownload/ReconciliationStepValidation"
import ServiceReconciliationDownload from "@/services/ServicesReconciliation/ServiceReconciliationDownload";
import SchemeLayoutReconciliationDownload from '@/schemes/SchemeLayoutReconciliationDownload';
const {steps} = SchemeLayoutReconciliationDownload;
export default {
    name: 'LayoutReconciliationDownload',
    components: {
        BasePreloader,
        AppSteps,
        BaseButton,
        ReconciliationStepAttach,
        ReconciliationStepValidation
    },
    /**
     * Данные компонента
     * @property {Array} steps - шаги для степпера
     * @property {Number} activeStepId - идентификатор активного шага
     * @property {Array} reconciliations - массив сверок
     * @property {Array} reconciliationsCheckData - сверки с данными проверки
     * @property {Array} dates - даты
     * @property {Boolean} isLoading - условие для прелоадера
     */
    data: () => ({
        steps: steps,
        activeStepId: 1,
        reconciliations: [],
        reconciliationsCheckData: [],
        dates: [],
        isLoading: false
    }),
    /**
     * Входные данные компонента
     * @property {Number} presetFileId
     */
    props: {
        presetFileId: {
            type: String,
            default: ''
        }
    },
    computed: {
        /**
         * Условие активности кнопки перехода к следующему шагу
         * @returns {Boolean}
         */
        isDisabledContinue() {
            let isDisabledContinue = false;
            switch(this.activeStepId) {
                case 1:
                    isDisabledContinue = this.reconciliations.length === 0 || this.isReconciliationsUnsuccess;
                    break;
                case 2:
                    isDisabledContinue = this.reconciliationsCheckData.length === 0/* || this.isReconciliationsValidUnsuccess*/;
                    break;
                default:
                    break;
            }
            return isDisabledContinue;
        },
        /**
         * Есть ли в сверках хотя бы один файл без шаблона
         * @returns {Boolean}
         */
        isReconciliationsValidUnsuccess() {
            const indexCheck = this.reconciliationsCheckData.findIndex(recon => {
                const {canUpload = true} = recon;
                return !canUpload;
            });
            return indexCheck !== -1;
        },
        /**
         * Есть ли в сверках хотя бы один файл без шаблона
         * @returns {Boolean}
         */
        isReconciliationsUnsuccess() {
            const index = this.reconciliations.findIndex(recon => {
                const {templateName = ''} = recon;
                return templateName === '';
            });
            return index !== -1;
        },
        /**
         * Заглововок для кнопки переходу к следующему шагу
         * @returns {String}
         */
        titleContinueStep() {
            let titleContinueStep = ''
            switch(this.activeStepId) {
                case 1:
                    titleContinueStep = 'Далее';
                    break;
                case 2:
                    titleContinueStep = 'Загрузить';
                    break;
                default:
                    break;
            }
            return titleContinueStep;
        }
    },
    methods: {
        /**
         * Закрыть
         */
        onCancel() {
            this.$router.push({name: 'reconciliationStart'});
        },
        onRedirectCreation(id = '-1'){
            this.$router.push({path: '/reconciliation?file=' + id});
        },
        onRedirectEdition(fileId = '-1', presetId = "-1"){
            this.$router.push({path: '/reconciliation?file=' + fileId + '&preset=' + presetId});
        },

        /**
         * Прикрепить
         * @param {Array} files - файлы
         * @param {Array} dates - даты
         */
        onAttach(files = [], dates = []) {
            this.dates = dates;
            const date = dates[0];
            const {month = 0, year = 0} = date;
            const dateFormatted = `${year}-${month < 10 ? '0' + month : month}-01`;
            this.isLoading = true;
            ServiceReconciliationDownload.attachFiles(files, dateFormatted, (reconciliations) => {
                this.reconciliations.push(...reconciliations);
                this.isLoading = false;
            });
        },
        /**
         * Прикрепить дату
         * @param {Array} dates - даты
         */
        async onAttachDate(dates = []) {
            this.reconciliations = [];
            this.dates = dates;
            const date = dates[0];
            const {month = 0, year = 0} = date;
            const dateFormatted = `${year}-${month < 10 ? '0' + month : month}-01`;
            let reconciliation = ServiceReconciliationDownload.checkFile(this.presetFileId, dateFormatted);
            let filesToPreset = [];
            filesToPreset.push(reconciliation);
            const filesAttached = await Promise.all(filesToPreset);
            const filesFiltered = filesAttached.filter(file => file !== null);
            ServiceReconciliationDownload.getReconciliations(filesFiltered, (reconciliations) => {
                this.reconciliations.push(...reconciliations);
                this.isLoading = false;
            });
        },
        /**
         * Переход на следующий шаг
         */
        onContinueStep() {
            switch(this.activeStepId) {
                case 1:
                    this.activeStepId += 1;
                    this.isLoading = true;
                    ServiceReconciliationDownload.checkReconciliations(this.reconciliations, (reconciliationsCheckData) => {
                        this.reconciliationsCheckData = reconciliationsCheckData;
                        this.isLoading = false;
                    });
                    break;
                case 2:
                    this.isLoading = true;
                    ServiceReconciliationDownload.downloadReconciliations(this.reconciliations, () => {
                        this.isLoading = false;
                        alert('Сверки успешно загружены');
                        this.onCancel();
                    });
                    break;
                default:
                    break;
            }
        },
        /**
         * Выбор шага
         * @param {Object} stepData - данные шага
         */
        onCheckStep(stepData = {}) {
            const {id = -1} = stepData;
            if (parseInt(this.activeStepId) > parseInt(id)) {
                this.activeStepId = parseInt(id);
            }
        },
        /**
         * Удалить элемент списка сверок
         * @param {String} id - идентификатор
         */
        onRemove(id = '-1') {
            const index = this.reconciliations.findIndex(recon => {
                const {id: reconId = -1} = recon;
                return String(reconId) === String(id);
            });
            if (index !== -1)
                this.reconciliations.splice(index, 1);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-download {
        .loading {
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: rgba(255, 255, 255, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 9999;
        }
        display: flex;
        height: 100%;
        .steps {
            &__cancel {
                margin-bottom: 30px;
            }
            &__continue {
                border-radius: 1000px;
                margin-top: 30px;
            }
        }
        &__left {
            width: 20%;
        }
        &__right {
            width: 80%;
            overflow: auto;
        }
    }
</style>
