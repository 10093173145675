<template>
    <div class="recon-attach">
        <div class="recon-attach__section">
            <div class="recon-attach__section-title">
                Выберите месяц для загрузки сверок
            </div>
            <div class="recon-attach__section-content">
                <date-picker-dropdown
                    :isMultiple="false"
                    :checkedDates="selectedDates"
                    @onApprove="onApprove"
                />
            </div>
        </div>
        <div class="recon-attach__section">
            <div class="recon-attach__section-actions">
                <base-button
                    :disabled="!selectedDates.length"
                    view="secondary"
                    class="recon-attach__section-btn"
                    @click="onAttach"
                >
                    Прикрепить
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
/** Компонент для прикрепления фалов сверок */
import DatePickerDropdown from "@/components/DatePickerDropdown";
import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'FormReconciliationSetDate',
    components: {
        DatePickerDropdown,
        BaseButton
    },
    /**
     * Данные компонента
     * @property {Array} files - файлы
     * @property {Array} selectedDates - выбранные даты
     */
    data: () => ({
        files: [],
        selectedDates: []
    }),
    methods: {
        /**
         * Выбор даты
         * @param {Array} dates - даты
         */
        onApprove(dates = []) {
            this.selectedDates = dates;
        },
        /**
         * Прикрепить
         */
        onAttach() {
            this.$emit('onAttachDate', this.selectedDates);
            this.files = [];
        }
    }
}
</script>

<style lang="scss" scoped>
    .recon-attach {
        &__section {
            &:not(:first-child) {
                margin-top: 20px;
            }
            &-content {
                margin-top: 8px;
            }
            &-title {
               font-weight: 500;
            }
            &-actions {
                margin-top: 8px;
                display: flex;
                flex-direction: row-reverse;
            }
            &-btn {
                border-radius: 1000px;
            }
        }
    }
</style>
