export default {
    /**
     * Входные данные компонента
     * @property {Object} reconciliation - сверка
     */
    props: {
        reconciliation: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        /**
         * Идентификатор
         * @returns {String}
         */
        id() {
            const {id = '-1'} = this.reconciliation;
            return String(id);
        },
        /**
         * Идентификатор шаблона
         * @returns {String}
         */
        presetId() {
            const {presetId = '-1'} = this.reconciliation;
            return String(presetId);
        },
        /**
         * Идентификатор файла
         * @returns {String}
         */
        fileId() {
            const {fileId = '-1'} = this.reconciliation;
            return String(fileId);
        },
        /**
         * Название файла
         * @returns {String}
         */
        fileName() {
            const {fileName = ''} = this.reconciliation;
            return String(fileName);
        },
        /**
         * Условие, найден ли шаблон
         * @returns {Boolean}
         */
        templateNameSuccess() {
            const {templateName = ''} = this.reconciliation;
            return String(templateName) !== '' ? true : false;
        },
        /**
         * Название шаблона
         * @returns {String}
         */
        templateName() {
            const {templateName = ''} = this.reconciliation;
            return !this.templateNameSuccess ? 'Шаблон не найден' : String(templateName);
        },
    }
}
