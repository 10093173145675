<template>
    <div class="recon-list-item">
        <div class="recon-list-item__file-name">
            {{fileName}}
        </div>
        <div class="recon-list-item__file-size">
            {{fileSize}}
        </div>
        <!-- <div class="recon-list-item__remove">
            <base-icon
                icon="icon-delete"
                color="#ADB0BB"
                style="font-size: 14px; cursor: pointer;"
                @click.native="onRemove"
            />
        </div> -->
        <div class="recon-list-item__template" :class="templateClass">
            <base-icon
                v-if="templateNameSuccess"
                color="#FFFFFF"
                icon="icon-tick"
                style="font-size: 9px;"
            />
        </div>
        <div
            :class="templateNameClass"
            class="recon-list-item__template-name"
        >
            {{templateName}}
        </div>
        <base-button
            v-if="!templateNameSuccess"
            view="secondary"
            class="steps__continue"
            @click="onRedirectCreate"
        >Создать шаблон</base-button>
        <base-button
            v-if="templateNameSuccess"
            view="secondary"
            class="steps__continue"
            @click="onRedirectEdit"
        >Изменить шаблон</base-button>
    </div>
</template>

<script>
/** Компонент для элемента списка результатов проверки сверок */
import BaseIcon from '@/components/Base/BaseIcon';
import MixinReconciliationItem from "@/mixins/MixinsReconciliation/MixinReconciliationItem";
import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'ReconciliationListItem',
    components: {BaseIcon, BaseButton},
    mixins: [MixinReconciliationItem],
    computed: {
        /**
         * Размер файла
         * @returns {String}
         */
        fileSize() {
            const {fileSize = ''} = this.reconciliation;
            return String(fileSize);
        },
        /**
         * Класс для названия шаблона
         * @returns {String}
         */
        templateNameClass() {
            const className = 'recon-list-item__template-name';
            return this.templateNameSuccess ? `${className}_success` : `${className}_danger`;
        },
        /**
         * Класс для шаблона
         * @returns {String}
         */
        templateClass() {
            const className = 'recon-list-item__template';
            return this.templateNameSuccess ? `${className}_success` : `${className}_danger`;
        }
    },
    methods: {
        /**
         * Удалить элемент списка сверок
         */
        onRemove() {
            this.$emit('onRemove', this.id);
        },
        onRedirectCreate(){
            this.$emit('onRedirectToTemplate', this.fileId);
        },
        onRedirectEdit(){
            this.$emit('onRedirectToTemplateEdition', this.fileId, this.presetId);
        },
    }
}
</script>

<style lang="scss" scoped>
    .recon-list-item {
        display: flex;
        justify-content: space-between;
        height: 44px;
        &__file-name {
            width: 248px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__file-size {
            color: #ADB0BB;
            width: 40px;
        }
        &__template {
            border-radius: 1000px;
            width: 14px;
            height: 14px;
            padding-left: 2px;
            &_success {
                background-color: #6EC87A;
            }
            &_danger {
                background-color: #F84967;
            }
            &-name {
                font-weight: 500;
                width: 96px;
                // white-space: nowrap;
                &_success {
                    color: #6EC87A;
                }
                &_danger {
                    color: #F84967;
                }
            }
        }
    }
</style>
