<template>
	<div class="step">
		<StepButton
			class="button"
			:isActive="isActive"
			@click.native="onCheck"
		>
			<slot />
		</StepButton>
	</div>
</template>

<script>
import StepButton from "./StepButton"

export default {
	name: "AppStep",
	components: {
		StepButton,
	},
	props: {
		isActive: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		onCheck() {
			this.$emit('check')
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.step {
	display: flex;
	align-items: center;
	
	.button {
		min-width: 200px;
		color: $assistant-color;
		font-size: 10px;
	}
}
</style>