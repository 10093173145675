<template>
    <div class="reconciliation-step-validation">
        <reconciliation-container
            v-for="(recon, reconIndex) in reconciliations"
            :key="reconIndex"
            :reconciliation="recon" 
        />
    </div>
</template>

<script>
/** Компонент для второго ашага */
import ReconciliationContainer from './ReconciliationContainer';
export default {
    name: 'ReconciliationStepValidation',
    components: {ReconciliationContainer},
    /**
     * Входные данные компонента
     * @property {Array} reconciliations - сверки
     */
    props: {
        reconciliations: {
            type: Array,
            default: () => ([])
        }
    },
}
</script>

<style lang="scss" scoped>
    .reconciliation-step-validation {
        margin-left: 60px;
    }
</style>