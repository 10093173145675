<template>
    <div>
<!--        <navigation-panel-->
<!--            :items="tabs"-->
<!--            :selected="tabCurrent"-->
<!--            @choose="onSelectTab" -->
<!--        />-->
<!--        <div class="reconciliation-table__actions">-->
<!--            <base-button -->
<!--                view="cancel"-->
<!--                form="oval"-->
<!--                class="reconciliation-table__actions-btn"-->
<!--                @click="onAcceptAll(true)" -->
<!--            >-->
<!--                Принять все-->
<!--            </base-button>-->
<!--            <base-button -->
<!--                view="cancel"-->
<!--                form="oval"-->
<!--                @click="onAcceptAll(false)" -->
<!--            >-->
<!--                Отклонить все-->
<!--            </base-button>-->
<!--        </div>-->
        <app-table
            primaryKey="id"
            :columns="columns"
            :rows="rows"
        >
            <template
				v-for="(column, columnIndex) in columnsPrices"
				v-slot:[column]="scopeData"
			>
                <div
                    v-if="getColumnType(scopeData) === 'cash'"
                    :key="columnIndex"
                    :class="getColumnClass(scopeData)"
                >
                    {{getColumnValue(scopeData)}}
                </div>
                <base-checkbox-accept
                    v-if="getColumnType(scopeData) === 'accept'"
                    :key="columnIndex"
                    :isAccept="getColumnAccept(scopeData)"
                    @onCheck="onAccept($event, scopeData)"
                />
            </template>
        </app-table>
    </div>
</template>

<script>
/** Компонент таблицы сверок */
// import NavigationPanel from '@/components/NavigationPanel';
import AppTable from '@/components/Table2/Table';
import BaseCheckboxAccept from '@/components/Base/BaseCheckboxAccept';
// import BaseButton from "@/components/Base/BaseButton";
export default {
    name: 'ReconciliationTable',
    components: {
        // NavigationPanel,
        AppTable,
        BaseCheckboxAccept,
        // BaseButton
    },
    /**
     * Входные данные компонента
     * @property {Array} reconciliations - сверки
     */
    props: {
        rows: {
            type: Array,
            default: () => ([])
        }
    },
    /**
     * Данные компонента
     * @property {Object} counterSides - колличество сторон
     * @property {String} tabCurrent - текущий табличный таб
     * @property {Array} columns - колонки
     * @property {Array} rows - строки
     */
    data: () => ({
        counterSides: {
            foundSides: 204,
            newSides: 40,
            missingSides: 12
        },
        tabCurrent: 'FoundSides',
        columns: [
            {
                prop: 'row_num',
                label: '№ строки',
                columnStyles: {width: '10%'}
            },
            {
                prop: 'message',
                label: 'Ошибка',
                columnStyles: {width: '90%'}
            }
        ],
        // rows: [
        //     {
        //         row: '1',
        //         message: 'ул. Ордатовская 25',
        //     },
        //     {
        //         row: '1',
        //         message: 'ул. Ордатовская 25',
        //     },
        //     {
        //         row: '2',
        //         message: 'ул. Ордатовская 25',
        //     }
        // ]
    }),
    computed: {
        /**
         * Табличные табы
         * @returns {Array}
         */
        tabs() {
            const {foundSides = 0, newSides = 0, missingSides = 0} = this.counterSides;
            const tabs = [
                {
                    id: 'FoundSides',
                    title: 'Найденные стороны',
                    postscript: foundSides,
                },
                {
                    id: 'NewSides',
                    title: 'Новые стороны',
                    postscript: newSides
                },
                {
                    id: 'MissingSides',
                    title: 'Отсутствующие стороны',
                    postscript: missingSides
                },
            ];
            return tabs;
        },
        /**
         * Колонки с ценами
         * @returns {Array}
         */
        columnsPrices() {
            const columnsPrices = this.columns.filter(column => {
                const {isSlot = false} = column;
                return isSlot;
            }).map(column => {
                const {prop = ''} = column;
                return prop;
            });
            return columnsPrices;
        },
        /**
         * Колонки с чекбоксом "принять"
         * @returns {Array}
         */
        columnsAccept() {
            const columnsAccept = this.columns.filter(column => {
                const {columnType = ''} = column;
                return columnType === 'accept';
            }).map(column => {
                const {prop = ''} = column;
                return prop;
            });
            return columnsAccept;
        }
    },
    methods: {
        /**
         * Выбор таба
         * @param {String} id - идентификатор
         */
        onSelectTab(id = '') {
            this.tabCurrent = String(id);
        },
        /**
         * Получить тип колонки
         * @param {Object} scopeData - табличные данные
         * @returns {String}
         */
        getColumnType(scopeData) {
            const {column = {}} = scopeData;
            const {columnType = ''} = column;
            let type = '';
            if (columnType === 'cashNew' || columnType === 'cashOld') {
                type = 'cash';
            }
            else if (columnType === 'accept') {
                type = 'accept';
            }
            return type;
        },
        /**
         * Получить класс колонки
         * @param {Object} scopeData - табличные данные
         * @returns {String}
         */
        getColumnClass(scopeData) {
            const {column = {}, row = {}, cell = ''} = scopeData;
            const {columnType = '', columnCompare = ''} = column;
            let className = 'reconciliation-table__cell-cash';
            if (columnType === 'cashNew') {
                const cashNew = parseInt(cell);
                const cashOld = parseInt(row[columnCompare]);
                className = cashNew > cashOld  ? `${className}_more` : `${className}_less`;
            }
            return className;
        },
        /**
         * Получить значение колонки
         * @param {Object} scopeData - табличные данные
         * @returns {String}
         */
        getColumnValue(scopeData) {
            const {cell = ''} = scopeData;
            const cellFormatted = `${parseInt(cell).toLocaleString('ru')} ₽`;
            return cellFormatted;
        },
        /**
         * Получить значение для колонки "принять"
         * @param {Object} scopeData - табличные данные
         * @returns {Boolean}
         */
        getColumnAccept(scopeData) {
            const {row = {}, column = {}} = scopeData;
            const {prop = ''} = column;
            const isAccept = Boolean(row[prop]);
            return isAccept;
        },
        /**
         * Принять цену
         * @param {Boolean} isAccept - текущее значение условия принять
         * @param {Object} scopeData - табличные данные
         */
        onAccept(isAccept, scopeData) {
            const {row = {}, column = {}} = scopeData;
            const {id = '-1'} = row;
            const {prop = ''} = column;
            const index = this.rows.findIndex(row => {
                const {id: rowId = '-1'} = row;
                return String(rowId) === String(id);
            });
            if (index !== -1) {
                let row = {...this.rows[index]};
                row[prop] = !isAccept;
                this.rows.splice(index, 1, row);
            }
        },
        /**
         * Принять все цены
         * @param {Boolean} isAccept - значение условия принять
         */
        onAcceptAll(isAccept) {
            const rowsNew = this.rows.map(row => {
                const columnsAccept = Object.keys(row).filter(column => this.columnsAccept.includes(String(column)));
                let newRow = {...row};
                columnsAccept.forEach(column => newRow[column] = isAccept);
                return newRow;
            });
            this.rows = [...rowsNew];
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-table {
        &__cell-cash {
            &_more {
                color: #F84967;
            }
            &_less {
                color: #6EC87A;
            }
        }
        &__actions {
            margin-top: 35px;
            margin-bottom: 30px;
            display: flex;
            &-btn {
                margin-right: 5px;
            }
        }
    }
</style>
