/** 
 * Схема для лэйаута загрузки сверки
 * @property {Array} steps - шаги степпера
 * @property {Array} reconciliations - примеры сверок
 */
const SchemeLayoutReconciliationDownload = {
    steps: [
        {
            id: 1,
            title: "Прикрепление файлов",
            canNext: true
        },
        {
            id: 2,
            title: "Проверка данных",
            canNext: true
        },
    ],
    reconciliations: [
        {
            id: '0',
            fileName: 'Название файла.xls',
            fileSize: '340 кб',
            templateName: 'Сверка RussOutDoor'
        },
        {
            id: '1',
            fileName: 'Очень длинное название файла в несколько строк.xls',
            fileSize: '1,3 мб',
            templateName: 'Сверка RussOutDoor'
        },
        {
            fileName: 'Пример файла без шаблона.xls',
            fileSize: '340 кб',
            templateName: 'Сверка RussOutDoor'
        },
    ]
};
export default SchemeLayoutReconciliationDownload;