<template>
	<div
		class="button"
		:class="getClasses"
	>
		<slot />
	</div>
</template>

<script>
export default {
	name: "StepButton",
	props: {
		isActive: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		getClasses() {
			return {
				'button_active': this.isActive
			}
		}
	}
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.button {
	height: 40px;
	border: 1px solid $primary-disabled-color;
	display: flex;
	align-items: center;
	padding: 0 20px;
	border-radius: 1000px;
	cursor: pointer;

	&_active {
		border-color: $success-color;
		color: #000 !important;
	}
}
</style>